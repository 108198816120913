.home {
  background: linear-gradient(
    33deg,
    rgba(242, 242, 255, 1) 0%,
    rgba(235, 249, 255, 1) 100%
  );
  padding: 0px 0px 10px 0px;
}
#particles-js {
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
* {
  box-sizing: border-box;
}

/*.home h2{
  font-size: 3rem;
  line-height: 4rem; }*/
.col-md-4.d-flex {
  display: flex;
  justify-content: center;
}

.small-text p {
  font-size: 0.95rem;
}
.c-orange {
  color: #05195f;
  font-weight: 400;
  font-size: 3rem;
}
.home p {
  width: fit-content;
  margin-top: 1rem;
  font-size: 1.2rem;
}
.h-btn:hover {
  background: #6f34fe;
  color: #fff;
  transform: translateY(-20px);
  transition-duration: 1s;
}
.link {
  text-decoration: none;
  font-size: 1.2rem;
  color: #0b247c;
  position: relative;
}
.link::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  top: 20px;
  background-color: #6f34fe;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.link:hover {
  color: #6f34fe;
}
.link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.social-icon {
  color: #000000;
}

.social-icon:hover,
.social-icon:active,
.social-icon:focus {
  color: #69b3a2 !important;
}

@keyframes floatimg {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-24px);
  }
  100% {
    transform: translateX(0);
  }
}

.home-section {
  min-height: 90vh;
  position: relative;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  overflow: hidden;
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s ease-out;
}

.content-wrapper.visible {
  opacity: 1;
  transform: translateY(0);
}

.profile-section {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-bottom: 4rem;
}

.image-container-1 {
  position: relative;
  width: 220px;
  height: 220px;
  animation: float 6s ease-in-out infinite;
}

.profile-image-1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.8);
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15));
  transition: transform 0.3s ease;
}

.image-backdrop-1 {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(45deg, #0984e3, #00b894);
  z-index: 1;
  opacity: 0.3;
}

.text-content {
  text-align: center;
  animation: fadeIn 1s ease-out 0.5s forwards;
  opacity: 0;
}

.name {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #2d3436, #6b88e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title {
  font-size: 1.5rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.separator {
  margin: 0 0.5rem;
  color: #6b88e6;
}

.description {
  font-size: 1.2rem;
  color: #4a5568;
  max-width: 600px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
}

.social-link {
  position: relative;
  color: #4a5568;
  font-size: 1.8rem;
  transition: all 0.3s ease;
  padding: 0.5rem;
}

.social-link:hover {
  color: #6b88e6;
  transform: translateY(-3px);
}

.hover-text {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  transition: all 0.3s ease;
}

.social-link:hover .hover-text {
  opacity: 1;
  bottom: -20px;
}
.scroll-wrapper{
  margin-bottom: 50px;
}
.scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: #4a5568;
  animation: bounce 2s infinite;
  cursor: pointer;
}

.scroll-arrow {
  font-size: 1.5rem;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
}

@media (min-width: 768px) {
  .profile-section {
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
  }

  .text-content {
    text-align: left;
  }

  .description {
    margin: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .content-wrapper,
  .image-container,
  .scroll-indicator {
    animation: none;
    transform: none;
  }
}
.scroll-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.scroll-wrapper:hover {
  color: inherit;
}

@media screen and (max-width: 768px) {
  .home-container {
    padding: 20px;
    text-align: center;
  }

  .intro-text h1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .intro-text p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .profile-image {
    width: 200px;
    height: 200px;
    margin: 20px auto;
  }
}

@media screen and (max-width: 414px) {
  .home-section {
    min-height: calc(100vh - 60px);
    padding-top: 40px;
  }

  .content-wrapper {
    padding: 0;
    gap: 1rem;
  }

  .profile-section {
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .image-container-1 {
    width: 180px;
    height: 180px;
    margin-top: 1rem;
  }

  .profile-image-1 {
    border-width: 3px;
  }

  .text-content {
    text-align: center;
    padding: 0rem;
  }

  .name {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    background: linear-gradient(45deg, #2d3436, #6b88e6);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  .separator {
    margin: 0 0.3rem;
  }

  .description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
  }

  .social-links {
    gap: 1.2rem;
    margin-top: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .social-link {
    font-size: 1.5rem;
    padding: 0.4rem;
  }

  .hover-text {
    display: none;
  }

  .scroll-indicator {
    bottom: 1.5rem;
    font-size: 0.9rem;
    left: 38%;
    transform: translateX(-50%);
    position: absolute;
    margin-bottom: 25px;
  }

  .scroll-arrow {
    font-size: 1.2rem;
  }

  /* Enhanced animations for mobile */
  .content-wrapper.visible {
    animation: mobileSlideUp 0.8s ease-out forwards;
  }

  @keyframes mobileSlideUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Smooth image loading animation */
  .profile-image-1 {
    animation: fadeInScale 0.6s ease-out forwards;
  }

  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* Optimize background for mobile */
  .particles-container {
    opacity: 0.4;
  }

  /* Better touch interaction */
  .social-link:active {
    transform: scale(0.9);
    transition: transform 0.2s ease;
  }
}