.portfolio-section {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #f6f8fa 0%, #f1f4f8 100%);
}

.portfolio-container {
  max-width: 1200px;
  margin: 0 auto;
}

.portfolio-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #2d3436 30%, #636e72 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.portfolio-subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 3rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.project-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-10px);
}

.project-image {
  position: relative;
  overflow: hidden;
  aspect-ratio: 16/9;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-card:hover .project-image img {
    transform: scale(1.1);
  }
  
.project-card:hover .project-overlay {
  opacity: 1;
}

.project-info {
  padding: 1.5rem;
}

.project-info h3 {
  margin: 0 0 1rem;
  color: #2d3436;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tag {
  padding: 0.3rem 0.8rem;
  background: #f1f4f8;
  border-radius: 20px;
  font-size: 0.8rem;
  color: #2d3436;
}

/* Modal Styles */
.project-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  transform: translateY(20px);
  animation: slideUp 0.3s ease forwards;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #2d3436;
}

.github-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  background: #24292e;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 1rem;
  transition: transform 0.3s ease;
  width: fit-content; 
  margin-left: auto;   
  margin-right: auto;
}

.github-link:hover {
  transform: translateY(-2px);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    width: 95%;
    padding: 1.5rem;
  }
}
.project-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1.5rem 0;
}

.skill-tag {
  background: #f3f4f6;
  color: #374151;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}