#contact {
  padding: 3rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  color: #333;
}

#contact h5 {
  color: var(--color-light);
  text-align: center;
  font-size: 0.9rem;
}

#contact h2 {
  color: var(--color-primary);
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.container.contact__container {
  width: var(--container-width-lg);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact__option {
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  padding: 1.8rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
}

.contact__option:hover {
  background: transparent;
  border: 2px solid #69b3a2;
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option h4 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.contact__option h5 {
  font-size: 0.9rem;
  color: #666;
}

.contact__option a {
  font-size: 0.8rem;
  color: #666;
  text-decoration: none;
  display: inline-block;
  margin-top: 0.7rem;
}

.contact__social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.contact__social-link {
  background: white;
  padding: 1rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: #333;
  transition: var(--transition);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  border: 1px solid #e0e0e0;
}

.contact__social-link:hover {
  background: transparent;
  border: 2px solid #69b3a2;
  color: var(--color-primary);
  transform: translateX(10px);
}

.contact__social-link svg {
  font-size: 1.2rem;
}

.contact__form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.form__input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form__input-group label {
  color: #333;
  font-size: 0.9rem;
}

.form__input-group input,
.form__input-group textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: white;
  border: 1px solid #e0e0e0;
  color: #333;
  font-size: 0.9rem;
  transition: var(--transition);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.form__input-group input::placeholder,
.form__input-group textarea::placeholder {
  color: var(--color-light);
  opacity: 0.7;
}

.form__input-group input:focus,
.form__input-group textarea:focus {
    background: transparent;
    border: 2px solid #69b3a2;
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    outline: none;
}

.btn-primary {
  width: max-content;
  padding: 0.75rem 1.2rem;
  border-radius: 1.2rem;
  background: #69b3a2;
  color: white;
  border: 2px solid #69b3a2;
  cursor: pointer;
  transition: var(--transition);
}

.btn-primary:hover {
  background: transparent;
  color: #69b3a2;
  border: 2px solid #69b3a2;
}

.footer__love {
  text-align: center;
  padding: 2rem 0;
  color: var(--color-light);
  margin-top: 3rem;
}

.heart-icon {
  color: #ff6b6b;
  display: inline-block;
  margin: 0 0.5rem;
  animation: heartBeat 1.5s infinite;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
    width: var(--container-width-md);
  }
}

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}

@media screen and (max-width: 768px) {
  .contact-container {
    padding: 20px;
  }

  .contact-form {
    width: 100%;
    margin: 0 auto;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 414px) {
  .contact-section {
    padding: 3rem 1rem;
    margin-top: 60px;
  }

  .contact-container {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }

  .contact-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    background: linear-gradient(45deg, #69b3a2, #251667);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #2c3e50;
    font-weight: 500;
  }

  .form-control {
    width: 100%;
    padding: 0.8rem;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.9);
  }

  .form-control:focus {
    border-color: #69b3a2;
    box-shadow: 0 0 0 3px rgba(105, 179, 162, 0.1);
    outline: none;
  }

  textarea.form-control {
    min-height: 120px;
    resize: vertical;
  }

  .submit-btn {
    background: linear-gradient(45deg, #69b3a2, #251667);
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.3s ease;
  }

  .submit-btn:active {
    transform: scale(0.98);
  }

  .contact-info {
    margin-top: 2rem;
    text-align: center;
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: #4a5568;
  }

  .contact-icon {
    color: #69b3a2;
    font-size: 1.2rem;
  }

  /* Animation for form elements */
  .form-group {
    opacity: 0;
    transform: translateY(10px);
    animation: slideUp 0.5s ease forwards;
  }

  .form-group:nth-child(1) { animation-delay: 0.1s; }
  .form-group:nth-child(2) { animation-delay: 0.2s; }
  .form-group:nth-child(3) { animation-delay: 0.3s; }
  .form-group:nth-child(4) { animation-delay: 0.4s; }

  @keyframes slideUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Success/Error messages */
  .message {
    padding: 0.8rem;
    border-radius: 12px;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    text-align: center;
    animation: fadeIn 0.3s ease;
  }

  .success {
    background: rgba(105, 179, 162, 0.1);
    color: #69b3a2;
  }

  .error {
    background: rgba(255, 99, 99, 0.1);
    color: #ff6363;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
}
