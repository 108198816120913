.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  transition: padding-top 0.3s, padding-bottom 0.3s;
  padding: 15px 0;  /* Reduced from 25px to 15px */
  height: 65px;     /* Added fixed height */
  display: flex;
  align-items: center;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
}

/* Remove the .glass class styles as we want a solid professional look */

.navbar.scrolled {
  padding: 10px 0;  /* Adjusted padding when scrolled */
  height: 55px;     /* Smaller height when scrolled */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  text-decoration: none;
  font-size: 2em;  /* Slightly reduced from 2em */
    padding-bottom: 19px;/* Adjusted padding */
  background: linear-gradient(120deg, #69b3a2, #251667);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.logo-text {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  letter-spacing: 2px;
  transition: color 0.3s;
}

.logo-text:hover {
  transform: scale(1.05);
  filter: brightness(1.2);
}

.logo-y, .logo-z {
  position: relative;
  transition: all 0.3s ease;
}

.nav-menu {
  display: flex;
  gap: 0.5rem;
  list-style: none;
  margin-left: 120px;


}

.nav-link {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.8em 1.5em;  /* Reduced padding */
  letter-spacing: 1.2px;
  color: #2c3e50;
  text-transform: uppercase;
  text-decoration: none;
}
.nav-link::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #69b3a2, #251667);
  transform: scaleX(0);
  transition: transform 0.3s;
}

.nav-link:hover {
  color: #69b3a2;
}

.nav-link:hover::after {
  transform: scaleX(1);
}

.nav-link.active {
  color: #69b3a2;
}

.nav-link.active::after {
  transform: scaleX(1);
}

.menu-icon {
  display: none;
  font-size: 12px;
  padding: 13px;
  color: white;
  background-color: #69b3a2;
  cursor: pointer;
}

.icon {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

@media (max-width: 992px) {
  .navbar-container {
    padding: 0 1rem;
    gap: 20rem;
  }
  .navbar {
    padding: 0;
    background-color: white;
  }
  .navbar-logo {
    font-size: 1.7em;
    padding: 8px;
  }
  .menu-icon {
    display: block;
    background-color: transparent;
    color: #69b3a2;
    font-size: 1.5rem;
  }

  .nav-menu {
    position: fixed;
    top: 70px;
    right: -100%;
    flex-direction: column;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.98);
    padding: 1rem 0;
    transition: 0.3s;
  }

  .nav-menu.active {
    right: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .nav-link {
    padding: 1em 2em;
    width: 100%;
    text-align: center;
  }

  .nav-link::after {
    bottom: 0;
  }
}

.nav-item {
  animation: fadeInDown 0.5s ease forwards;
  position: relative;
  transition: transform 0.3s ease;
}

.nav-item:hover {
  transform: translateY(-2px);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

::selection {
  background: #69b3a2;
  text-shadow: none;
}
.navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }

@media (max-width: 414px) {
  .navbar {
    width: 100%;
    height: 60px;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
  }
  .navbar-glass{
    width: 100%;
  }

  .navbar-container {
    padding: 0 1rem;
    gap: 16rem;
  }

  .navbar-logo {
    font-size: 1.5em;
    padding: 8px;
  }

  .menu-icon {
    display: block;
    position: relative;
    z-index: 100;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  .nav-menu {
    margin: 0;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    background: linear-gradient(135deg, rgba(255,255,255,0.98), rgba(255,255,255,0.95));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    transition: 0.4s ease-in-out;
  }

  .nav-menu.active {
    right: 0;
    backdrop-filter: blur(10px);
  }

  .nav-item {
    opacity: 0;
    transform: translateY(20px);
  }

  .nav-menu.active .nav-item {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s ease;
  }

  .nav-menu.active .nav-item:nth-child(1) { transition-delay: 0.1s; }
  .nav-menu.active .nav-item:nth-child(2) { transition-delay: 0.2s; }
  .nav-menu.active .nav-item:nth-child(3) { transition-delay: 0.3s; }
  .nav-menu.active .nav-item:nth-child(4) { transition-delay: 0.4s; }

  .nav-link {
    font-size: 1.2rem;
    padding: 1rem 2rem;
    color: #2c3e50;
    position: relative;
    transition: all 0.3s ease;
  }

  .nav-link::after {
    bottom: 0.8rem;
    height: 3px;
    opacity: 0.8;
  }

  .nav-link:active {
    transform: scale(0.95);
  }
}