
.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.signin-card {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.signin-card h2 {
  color: #2d3748;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
}

.signin-card p {
  color: #718096;
  margin-bottom: 1.5rem;
}

.signin-form .form-group {
  margin-bottom: 1rem;
}

.signin-form input[type="email"],
.signin-form input[type="password"] {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.signin-form input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  color: #718096;
}

.form-options a {
  color: #4299e1;
  text-decoration: none;
}

.signin-button {
  width: 100%;
  padding: 0.75rem;
  background: #4299e1;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 20px;
}

.signin-button:hover {
  background: #3182ce;
}

.signin-footer {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  color: #718096;
}

.signin-footer a {
  color: #4299e1;
  text-decoration: none;
  font-weight: 600;
}

.signin-footer a:hover {
  text-decoration: underline;
}