.verification-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #a8acbc 0%, #764ba2 100%);
    perspective: 1000px;
  }
  
  .verification-card {
    padding: 3rem;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    text-align: center;
    transform-style: preserve-3d;
    animation: cardFloat 3s ease-in-out infinite;
    backdrop-filter: blur(10px);
  }
  
  .verification-card h2 {
    color: #2d3748;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    animation: fadeIn 0.8s ease-out;
  }
  
  .verification-card p {
    color: #4a5568;
    margin-bottom: 2rem;
    animation: fadeIn 0.8s ease-out 0.2s backwards;
  }
  
  .verification-card input {
    width: 305px;
    padding: 1rem;
    margin: 1rem 0;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.9);
    animation: slideUp 0.8s ease-out 0.4s backwards;
  }
  
  .verification-card input:focus {
    outline: none;
    border-color: #667eea;
    box-shadow: 0 0 0 3px rgba(102, 126, 234, 0.25);
    transform: translateY(-2px);
  }
  
  .verification-card button {
    width: 100%;
    padding: 1rem;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    animation: slideUp 0.8s ease-out 0.6s backwards;
  }
  
  .verification-card button:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .verification-card button:active {
    transform: translateY(-1px);
  }
  
  .error-message {
    color: #e53e3e;
    background-color: rgba(254, 215, 215, 0.8);
    padding: 1rem;
    border-radius: 12px;
    margin: 1rem 0;
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes cardFloat {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
    20%, 40%, 60%, 80% { transform: translateX(5px); }
  }